import * as React from "react"
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Grid, Typography } from "@material-ui/core";
import { StaticImage } from "gatsby-plugin-image";
import Checkmark from "../Checkmark";

interface GoalsGraphicListItem {
    id: string;
    text: string;
}
interface GoalsGraphicList {
    items: GoalsGraphicListItem[];
    title: string;
}

interface Props {
    ListTitle: string;
    list: GoalsGraphicList;
    listSubtitle: string;
    title: string;
    mapTextFlag3: string;
    mapTextFlag2: string;
    mapTextFlag1: string;
    mapEntryText: string;
    mapEndingText: string;
    mapBubbleText3: string;
    mapBubbleText2: string;
    mapBubbleText1: string;
    mapBubbleImage1: any;
    mapBubbleImage2: any;
    mapBubbleImage3: any;
}

interface AbsoluteContainerProps {
    left?: string;
    top?: string;
    right?: string;
    bottom?: string;
    height?: number;
    width?: number;
    background?: string;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            textAlign: 'center',
        },
        title: {
            padding: 100,
        },
        wrapper: {
            position: 'relative',
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'block',
            },
        },
        wrapperSmallScreen: {
            display: 'block',
            '& > h4': {
                margin: '45px 0'
            },
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        map: {
            margin: 100,
            background: 'white',
        },
        entryText: {
            maxWidth: '300px'
        },
        alignCenter: {
            alignItems: 'center',
        },
        circle: {
            height: 100,
            width: 100,
            background: 'white',
            borderRadius: '50%',
            boxShadow: '0 12px 12px 0 rgba(0, 0, 0, 0.05)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            '& > img': {
                maxWidth: '50px',
            }
        },
        title2: {
            paddingTop: 100,
            paddingBottom: 30,
        },
        title3: {
            paddingTop: 30,
            paddingBottom: 100,
        },
        list: {
            textAlign: 'left',
            margin: '0 auto',
            maxWidth: '500px',
        },
        mapTextFlag1: {
            maxWidth: '220px',
        },
        rotate: {
            transform: 'rotate(90deg)'
        },
    }),
);

const AbsoluteContainer: React.FC<AbsoluteContainerProps> = ({
    left,
    top,
    right,
    bottom,
    width,
    height,
    background,
    children,
    className
}) => {
    const classes = useStyles();

    return <div style={{
        left,
        top,
        right,
        bottom,
        height,
        width,
        position: 'absolute',
        background
    }}
        className={className}
    >
        {children}
    </div>
}

const GoalsGraphic: React.FC<Props> = ({
    ListTitle,
    list,
    listSubtitle,
    mapBubbleImage1,
    mapBubbleImage2,
    title,
    mapTextFlag3,
    mapTextFlag2,
    mapTextFlag1,
    mapEntryText,
    mapEndingText,
    mapBubbleText3,
    mapBubbleText2,
    mapBubbleText1,
    mapBubbleImage3
}) => {
    const classes = useStyles();

    return <div className={classes.root}>
        <Typography variant="h2" className={classes.title}>
            {title}
        </Typography>
        <div className={classes.wrapper}>
            <StaticImage
                src="../../images/map.svg"
                quality={95}
                alt=""
                className={classes.map}
            />

            <AbsoluteContainer top={'13%'} left={'5%'}>
                <Grid container spacing={3} className={[classes.alignCenter, classes.entryText].join(' ')}>
                    <Grid item xs={8}>
                        <Typography variant="h3" gutterBottom>
                            {mapEntryText}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <StaticImage
                            src="../../images/map-arrow.svg"
                            quality={95}
                            alt=""
                            style={{ width: '54px' }}
                        />
                    </Grid>
                </Grid>
            </AbsoluteContainer>
            {mapTextFlag1 && <AbsoluteContainer top={'13%'} left={'30%'} className={classes.mapTextFlag1}>
                <StaticImage
                    src="../../images/flag.svg"
                    quality={95}
                    alt=""
                />
                <Typography variant="h4" gutterBottom>
                    {mapTextFlag1}
                </Typography>
            </AbsoluteContainer>}
            <AbsoluteContainer top={'13%'} left={'55%'}>
                <div className={classes.circle}>
                    <img src={mapBubbleImage1.localFile.publicURL} />
                </div>
                <Typography variant="h4" gutterBottom>
                    {mapBubbleText1}
                </Typography>
            </AbsoluteContainer>
            <AbsoluteContainer top={'25%'} left={'75%'}>
                <Grid container spacing={3} className={classes.alignCenter}>
                    <Grid item xs={6}>
                        <StaticImage
                            src="../../images/flag.svg"
                            quality={95}
                            alt=""
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1">
                            {mapTextFlag2}
                        </Typography>
                    </Grid>
                </Grid>
            </AbsoluteContainer>
            <AbsoluteContainer top={'40%'} left={'65%'}>
                <div className={classes.circle}>
                    <img src={mapBubbleImage2.localFile.publicURL} />
                </div>
                <Typography variant="h4" gutterBottom>
                    {mapBubbleText2}
                </Typography>
            </AbsoluteContainer>
            <AbsoluteContainer top={'40%'} left={'40%'}>
                <StaticImage
                    src="../../images/flag.svg"
                    quality={95}
                    alt=""
                />
                <Typography variant="body1">
                    {mapTextFlag3}
                </Typography>
            </AbsoluteContainer>
            <AbsoluteContainer top={'55%'} left={'15%'}>
                <Grid container spacing={3} className={classes.alignCenter}>
                    <Grid item xs={6}>
                        <Typography variant="body1">
                            {mapBubbleText3}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.circle}>
                            <img src={mapBubbleImage3.localFile.publicURL} />
                        </div>
                    </Grid>
                </Grid>
            </AbsoluteContainer>
            <AbsoluteContainer top={'72%'} left={'41%'}>
                <Grid container spacing={3} className={classes.alignCenter}>
                    <Grid item xs={4}>
                        <StaticImage
                            src="../../images/map-arrow.svg"
                            quality={95}
                            alt=""
                            style={{ width: '54px' }}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="h3" gutterBottom>
                            {mapEndingText}
                        </Typography>
                    </Grid>
                </Grid>
            </AbsoluteContainer>
        </div>
        <div className={classes.wrapperSmallScreen}>
            <Typography variant="h4" gutterBottom>
                {mapEntryText}
            </Typography>
            <StaticImage
                src="../../images/map-arrow.svg"
                quality={95}
                alt=""
                className={classes.rotate}
            />
            {mapTextFlag1 && <React.Fragment>
                <Typography variant="h4" gutterBottom>
                    {mapTextFlag1}
                </Typography>
                <StaticImage
                    src="../../images/map-arrow.svg"
                    quality={95}
                    alt=""
                    className={classes.rotate}
                />
            </React.Fragment>}
            <Typography variant="h4" gutterBottom>
                {mapBubbleText1}
            </Typography>
            <StaticImage
                src="../../images/map-arrow.svg"
                quality={95}
                alt=""
                className={classes.rotate}
            />
            <Typography variant="h4">
                {mapTextFlag2}
            </Typography>
            <StaticImage
                src="../../images/map-arrow.svg"
                quality={95}
                alt=""
                className={classes.rotate}
            />
            <Typography variant="h4" gutterBottom>
                {mapBubbleText2}
            </Typography>
            <StaticImage
                src="../../images/map-arrow.svg"
                quality={95}
                alt=""
                className={classes.rotate}
            />
            <Typography variant="h4">
                {mapTextFlag3}
            </Typography>
            <StaticImage
                src="../../images/map-arrow.svg"
                quality={95}
                alt=""
                className={classes.rotate}
            />
            <Typography variant="h4">
                {mapBubbleText3}
            </Typography>
            <StaticImage
                src="../../images/map-arrow.svg"
                quality={95}
                alt=""
                className={classes.rotate}
            />
            <Typography variant="h4" gutterBottom>
                {mapEndingText}
            </Typography>

        </div>
        <Typography variant="h3" gutterBottom className={classes.title2}>
            {ListTitle}
        </Typography>
        <div className={classes.list}>{list.items.map(item => <Checkmark text={item.text} typography="body1" />)}</div>
        <Typography variant="h4" gutterBottom className={classes.title3}>
            {listSubtitle}
        </Typography>
    </div>
}

export default GoalsGraphic

