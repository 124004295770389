
export const LOCALES = [
    "en",
    "cs-CZ",
]

export type Locale = "en" | "cs-CZ"

export function markdownToPlainText(markdown: string): string {
    // Remove Markdown headers (lines starting with #)
    let plainText = markdown.replace(/^# (.+)$/gm, '$1\n');

    // Remove emphasis (italic and bold)
    plainText = plainText.replace(/(?:\*|_){1,3}([^*_]+)(?:\*|_){1,3}/g, '$1');

    // Remove inline code
    plainText = plainText.replace(/`([^`]+)`/g, '$1');

    // Remove links
    plainText = plainText.replace(/\[([^\]]+)\]\(([^\)]+)\)/g, '$1 ($2)');

    // Remove list indicators and convert to plain text
    plainText = plainText.replace(/(?:-|\d+\.)\s/g, '');

    // Remove blockquotes
    plainText = plainText.replace(/^\s*> /gm, '');

    // Remove horizontal rules
    plainText = plainText.replace(/^\s*[-*_]{3,}\s*$/gm, '');

    return plainText.trim();
}
