import * as React from "react"
import { graphql, Link, PageProps } from "gatsby"
import { markdownToPlainText } from '../utils';

import Layout from "../components/Layout"
import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core"
import Row from "../components/Row"
import Headline from "../components/Headline"
import Section from "../components/Section"
import theme from "../gatsby-theme-material-ui-top-layout/theme"
import DividedLists from "../components/DividedLists"
import GoalsGraphic from "../components/GoalsGraphic"
import Seo from "../components/seo"
import { FooterProps } from "../components/Footer"
import { NavigationProps } from "../components/Header/navigation"
import StyledAccordion from "../components/StyledAccordion"
import ReactMarkdown from "react-markdown"
import Badge from "../components/Badge"
import BlogPosts from "../components/BlogPosts"
import Video from "../components/Video"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionHeadline: {
      padding: "25px 0 50px 0",
    },
    markdownBody: {
      "& > img": {
        maxWidth: "100%",
      },
      "& > p > img": {
        maxWidth: "100%",
        marginTop: "1rem"
      },
      "& > h2": {
        lineHeight: 1.5,
        marginTop: "2rem",
        color: "rgba(234, 22, 116, .75)",
        [theme.breakpoints.down("xs")]: {
          color: "rgba(234, 22, 116, .75)",
        },
      },
      "& > h3": {
        marginTop: "2em",
        color: "#202338"
      },
      "& > p > a": {
        color: "rgba(234, 22, 116, .75)",
      },
    },
    accordion: {
      marginBottom: "50px",
    },
    title: {
      display: "flex",
      alignItems: "center",
    },
    titleText: {
      padding: "0px 0",
      fontSize: "40px",
    },
    titleImage: {
      width: "60px",
      height: "60px",
      borderRadius: "50px",
      marginRight: "16px",
    },
    description: {
      fontSize: "15px",
      fontStyle: "italic",
    },
    blogBody: {
      margin: "0 0 50px 0",
      fontSize: "18px",
      color: "#8690a0",
      lineHeight: 1.94
    },
    link: {
      color: theme.palette.secondary.dark,
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    onlyDesktop: {
      "@media (max-width: 900px)": {
        display: "none",
      },
    },
  })
)

type DataProps = {
  strapiSubPages: any
  strapiNavigation: NavigationProps
  strapiFooter: FooterProps
}

const IndexPage: React.FC<PageProps<DataProps>> = ({ data }) => {
  const classes = useStyles()
  const navigation = data.strapiNavigation
  const footer = data.strapiFooter
  const strapiPage = data.strapiSubPages.page
  const headline = strapiPage.Headline
  const rows = strapiPage.Rows
  const dividedLists = strapiPage.SeparatedLists
  const graphic = strapiPage.MapSection
  const accordion = strapiPage.Accordion && strapiPage.Accordion.question
  const blogPosts = strapiPage.BlogPosts
  const blogPostHeader = strapiPage.BlogPostHeader
  const blogPostContent = strapiPage.BlogPostContent
  const isBlogPost = Boolean(strapiPage.BlogPostHeader);

  return (
    <Layout navigation={navigation} footer={footer}>
      {headline && (
        <Seo
          title={`${headline.titleBefore ? headline.titleBefore : ""} ${headline.highlighted ? headline.highlighted : ""
            } ${headline.titleAfter ? headline.titleAfter : ""}`}
          description={markdownToPlainText(headline.text || "")}
          imageURL={headline?.rightImage?.url || undefined}
        />
      )}
      {!isBlogPost && headline && headline.highlighted && (
        <Section color="white">
          <Headline {...headline} />
        </Section>
      )}

      {rows && rows.length !== 0 && (
        <Section color={theme.palette.secondary.main}>
          <span>
            {strapiPage.RowsHeadline && (
              <Typography variant="h2" align="center">
                {strapiPage.RowsHeadline}
              </Typography>
            )}
            {rows.map((row, i) => {
              const {
                id,
                title,
                description,
                buttonText,
                image,
                badge,
                anchor,
              } = row
              const gatsbyImage =
                image && image.localFile.childImageSharp.gatsbyImageData

              return (
                <Row
                  key={id}
                  title={title}
                  description={description}
                  imageRight={i % 2 ? true : false}
                  url="/"
                  image={gatsbyImage}
                  buttonText={buttonText}
                  badge={badge}
                  anchor={anchor}
                />
              )
            })}
          </span>
        </Section>
      )}

      {dividedLists && dividedLists.length !== 0 && (
        <Section color={theme.palette.secondary.main}>
          <DividedLists lists={dividedLists} />
        </Section>
      )}

      {graphic && graphic.length !== 0 && (
        <Section color="white">
          <GoalsGraphic {...graphic} />
        </Section>
      )}

      {/* ACCORDION SECTION */}
      {strapiPage.Accordion && accordion && (
        <Section color="white">
          {strapiPage.AccordionHeadline && (
            <Typography
              className={classes.sectionHeadline}
              variant="h3"
              align="center"
            >
              {strapiPage.AccordionHeadline}
            </Typography>
          )}
          <StyledAccordion items={accordion} className={classes.accordion} />
        </Section>
      )}

      {/* BLOG DETAIL SECTION */}
      {blogPostContent && (
        <Section color="white">
          {blogPostHeader && (
            <div>
              <Grid container spacing={3}>
                <Grid item md={2} className={classes.onlyDesktop} />
                <Grid
                  item
                  xs={12}
                  md={8}
                  container
                  justifyContent="space-between"
                >
                  <Grid item xs={6}>
                    <Badge text={blogPostHeader.category} alternative />
                  </Grid>
                  <Grid item xs={3} style={{ textAlign: "right" }}>
                    <Typography variant="h4">
                      {blogPostHeader.link && (
                        <Link
                          to={blogPostHeader.link.link}
                          className={classes.link}
                        >
                          {blogPostHeader.link.text}
                        </Link>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={false} md={2} className={classes.onlyDesktop} />
                <Grid item xs={false} md={2} className={classes.onlyDesktop} />
                <Grid item xs={12} md={8} container justifyContent="center">
                  <div className={classes.title}>
                    {/* {blogPostHeader.titleIcon &&
                      blogPostHeader.titleIcon[0] &&
                      blogPostHeader.titleIcon[0].localFile &&
                      blogPostHeader.titleIcon[0].localFile.publicURL && (
                        <img
                          className={classes.titleImage}
                          src={blogPostHeader.titleIcon[0].localFile.publicURL}
                        />
                      )} */}
                    {
                      blogPostHeader.title && (
                        <Typography
                          variant="h2"
                          align="center"
                          className={classes.titleText}
                        >
                          {blogPostHeader.title}
                        </Typography>
                      )
                    }
                  </div>
                </Grid>
                <Grid item xs={false} md={2} className={classes.onlyDesktop} />
                <Grid item xs={false} md={2} className={classes.onlyDesktop} />
                <Grid item xs={12} md={8}>
                  <Typography className={classes.description} align="center">
                    <ReactMarkdown
                      linkTarget="_blank"
                      className={classes.markdownBody}
                    >
                      {blogPostHeader.description}
                    </ReactMarkdown>
                  </Typography>
                </Grid>
                <Grid item xs={false} md={2} />
              </Grid>
            </div>
          )}

          <Grid container spacing={3}>
            <Grid item xs={false} md={2} className={classes.onlyDesktop} />
            <Grid item xs={12} md={8}>
              <ReactMarkdown
                linkTarget="_blank"
                className={[classes.markdownBody, classes.blogBody].join(" ")}
              >
                {blogPostContent}
              </ReactMarkdown>
            </Grid>
            <Grid item xs={false} md={2} />
          </Grid>
        </Section>
      )}

      {/* VIDEO SECTION */}
      {strapiPage.Video && strapiPage.Video.title && (
        <Section color="white">
          <Video
            title={strapiPage.Video.title}
            description={strapiPage.Video.Description}
            link={strapiPage.Video.link}
            youtubeLink={strapiPage.Video.youtubeLink}
          />
        </Section>
      )}

      {/* BLOG POSTS SECTION */}
      {blogPosts && blogPosts.length !== 0 && (
        <BlogPosts
          title={blogPosts.title}
          blogPosts={blogPosts.posts.map(post => {
            return {
              ...post,
              image: post.image.localFile.publicURL,
            }
          })}
        />
      )}
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query ($locale: String, $slug: String) {
    strapiSubPages(locale: { eq: $locale }, slug: { eq: $slug }) {
      localizations {
        locale
      }
      slug
      locale
      pageTitle
      page {
        # Video {
        #   Description
        #   id
        #   link {
        #     id
        #     link
        #     text
        #   }
        #   quotation {
        #     id
        #     text
        #   }
        #   title
        #   youtubeLink
        # }
        Headline {
          titleAfter
          badge
          dividedLinks {
            link
            text
            id
          }
          highlighted
          id
          inputButtonText
          inputLabel
          rightImage {
            id
            url
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          titleBefore
          text
          mainLink {
            link
            text
          }
        }
        id
        RowsHeadline
        BottomHeadline {
          badge
          highlighted
          id
          inputButtonText
          inputLabel
          titleAfter
          titleBefore
        }
        MapSection {
          ListTitle
          list {
            items {
              id
              text
            }
            title
          }
          listSubtitle
          mapBubbleImage1 {
            localFile {
              publicURL
            }
          }
          mapBubbleImage2 {
            localFile {
              publicURL
            }
          }
          title
          mapTextFlag3
          mapTextFlag2
          mapTextFlag1
          mapEntryText
          mapEndingText
          mapBubbleText3
          mapBubbleText2
          mapBubbleText1
          mapBubbleImage3 {
            localFile {
              publicURL
            }
          }
        }
        QuotationSection {
          highlighted
          text
        }
        Rows {
          badge
          buttonText
          description
          id
          title
          url
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          anchor
        }
        SeparatedLists {
          id
          title
          items {
            text
            id
          }
        }
        AccordionHeadline
        Accordion {
          question {
            answer
            description
            id
            title
          }
        }
        BlogPostContent
        BlogPostHeader {
          category
          description
          id
          image {
            localFile {
              publicURL
            }
          }
          title
          titleIcon {
            localFile {
              publicURL
            }
          }
        }

        BlogPosts {
          id
          title
          posts {
            description
            id
            image {
              localFile {
                publicURL
              }
            }
            link
            title
          }
        }
      }
    }
    strapiNavigation(locale: { eq: $locale }) {
      locale
      navigationItem {
        title
        customLink
        NavigationSubItems {
          title
          sub_page {
            slug
            pageTitle
          }
          slug
        }
      }
      ContactUsButton {
        link
        text
      }
    }
    strapiFooter(locale: { eq: $locale }) {
      bottomLinks {
        id
        link
        text
      }
      locale
      columns {
        FooterColumnItem {
          text
          link
          anchor
        }
        Title
      }
      contactsSectionLinks {
        link
        text
      }
      city
      state
      zipCode
      email
      id
      identification
      mobilePhone
      street
      socialMedia {
        name
        image {
          localFile {
            publicURL
          }
        }
        link
      }
      bottomText
      bottomHeadlineSection {
        mainLink {
          link
          text
        }
        inputButtonText
        highlighted
        inputLabel
        titleAfter
        titleBefore
      }
    }
  }
`
