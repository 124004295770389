import * as React from "react"
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { GatsbyImage, IGatsbyImageData, StaticImage } from "gatsby-plugin-image"
import { Paper, Typography } from "@material-ui/core";
import { Link } from "gatsby";

function isHTML(str) {
    return /<\/?[a-z][\s\S]*>/gmi.test(str)
}


interface Props {
    imageUrl?: string | IGatsbyImageData;
    title: string;
    description: string;
    link: string
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        link: {
            textDecoration: "none",
        },
        paper: {
            borderRadius: "10px",
            overflow: "hidden",
            cursor: "pointer",
        },
        root: {
            padding: '15px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
        },
        image: {
            height: "200px",
            width: "100%",
            display: "flex",
            overflow: "hidden",
            alignItems: "center",
            justifyContent: "center",
            "& > img": {
                width: "100%",
                minHeight: "100%"
            }
        },
        title: {
            padding: '20px 0',
        },
    }),
);

const BlogCard: React.FC<Props> = ({ imageUrl, title, description, link }) => {
    const classes = useStyles();

    const isDescriptionHTML = isHTML(description)
    let imageStyle = {
        width: isDescriptionHTML ? '50%' : '100%',
        minHeight: 'auto',
    }

    return <Link to={link} target="_blank" className={classes.link}>
        <Paper className={classes.paper}>
            <div className={classes.image}>
                {typeof imageUrl === "string" ? <img src={imageUrl} style={imageStyle} /> : <GatsbyImage alt="" image={imageUrl} style={imageStyle} />}
            </div>
            <div className={classes.root}>
                {
                    (title && !isDescriptionHTML) && (
                        <Typography variant="h4" gutterBottom className={classes.title}>
                            {title}
                        </Typography>
                    )
                }
                {
                    !isDescriptionHTML ? (
                        <Typography variant="body1" gutterBottom>
                            {description}
                        </Typography>
                    ) : (
                        < div dangerouslySetInnerHTML={{ __html: description }} />
                    )
                }
            </div>
        </Paper>
    </Link>
}

export default BlogCard
