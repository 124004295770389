import * as React from "react"
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Grid, Typography } from "@material-ui/core";
import Checkmark from "../Checkmark";

interface List {
    title: string;
    items: {
        text: string;
    }[]
}

interface Props {
    lists: List[]
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: 15,
            width: '100%',
            [theme.breakpoints.up('md')]: {
                padding: 60,
            },
        },
        borderRight: {
            [theme.breakpoints.up('md')]: {
                borderRight: `solid #eee 2px`
            },
        },
        title: {
            textAlign: 'center',
            [theme.breakpoints.up('md')]: {
                textAlign: 'left',
            },
        },
        list: {
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
        }
    }),
);

const DividedLists: React.FC<Props> = ({ lists }) => {
    const classes = useStyles();

    return <Grid container spacing={6} className={classes.root}>
        {lists.map((list, i) => <Grid item xs={12} md={6} className={[i === 0 && classes.borderRight, classes.list].join(' ')}>
            <span>
                <Typography variant="h3" gutterBottom className={classes.title}>
                    {list.title}
                </Typography>
                {list.items.map(item => <Checkmark text={item.text} typography="body1" />)}
            </span>
        </Grid>)}
    </Grid>
}

export default DividedLists
