import * as React from "react"
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Grid, Typography } from "@material-ui/core";
import BlogCard from "./BlogCard";
import Section from "../Section";
import { Button } from "gatsby-theme-material-ui";
import { IGatsbyImageData } from "gatsby-plugin-image";

interface BlogPost {
    id: string
    description: string;
    title: string;
    image: string;
    link: string;
}
interface Props {
    title: string;
    blogPosts: BlogPost[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '50px 0',
        },
        posts: {
            padding: '40px 0'
        },
        button: {
            display: 'block',
            margin: '0 auto',
        }
    }),
);

const BlogPosts: React.FC<Props> = ({ title, blogPosts }) => {
    const classes = useStyles();

    return <Section color="#fff">
        <div className={classes.root}>
            {
                title && (
                    <Typography variant="h2" align="center">
                        {title}
                    </Typography>
                )
            }
            <Grid container spacing={3} className={classes.posts}>
                {blogPosts.map(post => <Grid item xs={12} sm={6} md={4}>
                    <BlogCard
                        key={post.id}
                        title={post.title}
                        description={post.description}
                        imageUrl={post.image}
                        link={post.link}
                    />
                </Grid>)}
            </Grid>
            {/* <Button variant="outlined" color="primary" className={classes.button}>
                View More Blog Posts
            </Button> */}
        </div>
    </Section>
}

export default BlogPosts
