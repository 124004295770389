import React from "react"
import CssBaseline from "@material-ui/core/CssBaseline"
import Header from "../Header"
import { ReactChildren } from "react"
import Footer, { FooterProps } from "../Footer"
import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core"
import LocaleProvider from "../../context/LocaleContext"
import { NavigationProps } from "../Header/navigation"
import { Link } from "gatsby"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      textDecoration: "none",
    },
    link: {
      display: "flex",
      justifyContent: "center",
      margin: "50px",
    },
    videoContainer: {
      overflow: "scroll",
    },
  })
)

interface Props {
  youtubeLink?: string
  title?: string
  description?: string
  link?: {
    link: string
    text: string
  }
}

const Video: React.FunctionComponent<Props> = ({
  youtubeLink,
  title,
  description,
  link,
}) => {
  const classes = useStyles()

  return (
    <Grid container spacing={3}>
      {title && (
        <Grid item xs={12}>
          <Typography variant="h3">{title}</Typography>
        </Grid>
      )}
      {description && (
        <Grid item xs={12} md={6}>
          <Typography variant="body1" gutterBottom>
            {description}
          </Typography>
        </Grid>
      )}
      {youtubeLink && (
        <Grid item xs={12} md={6} className={classes.videoContainer}>
          <iframe
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${youtubeLink}`}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Grid>
      )}
      {link && link.text && (
        <Grid item xs={12} className={classes.link}>
          <Link to={link.link} className={classes.button}>
            <Button variant="contained" color="primary">
              {link.text}
            </Button>
          </Link>
        </Grid>
      )}
    </Grid>
  )
}

export default Video
