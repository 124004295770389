import * as React from "react"
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Grid, Typography } from "@material-ui/core"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { Button } from "gatsby-theme-material-ui"
import { GatsbyImage } from 'gatsby-plugin-image'
import Badge from "../Badge";
import { Link } from "gatsby";
import ReactMarkdown from "react-markdown"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '50px 0',
            [theme.breakpoints.down('sm')]: {
                padding: '15px 0',
            },
        },
        description: {
            padding: '10px 0 20px 0',
        },
        link: {
            textDecoration: 'none'
        },
        pc: {
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        mobile: {
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        markdown: {
            '& a': {
                color: '#ea1674',
                fontWeight: 'bold',
                textDecoration: 'underline',
            }
        },
        imageWrapper: {
            height: '250px',
            overflow: 'hidden',
            [theme.breakpoints.up('md')]: {
                height: '550px',
            },
        },
        image: {
            maxWidth: '100%',
        },
    }),
);

interface Props {
    url: string;
    image?: IGatsbyImageData;
    title: string;
    description: string;
    imageRight?: boolean;
    buttonPrimary?: boolean;
    buttonText: string;
    badge?: string;
    anchor?: string;
}

const Row: React.FC<Props> = ({ imageRight, url, image, title, description, buttonPrimary, buttonText, badge, anchor }) => {
    const classes = useStyles();

    return (
        <div id={anchor}>
            <Grid container spacing={3} className={[classes.root, classes.pc].join(" ")}>
                {imageRight ? <React.Fragment>
                    <Grid item md={5}>
                        {badge && <Badge text={badge} />}
                        <Typography variant="h3" gutterBottom>
                            {title}
                        </Typography>
                        <Typography variant="body1" gutterBottom className={classes.description}>
                            <ReactMarkdown linkTarget="_blank" className={classes.markdown}>{description}</ReactMarkdown>
                        </Typography>
                        {buttonText && <Link to={url} className={classes.link}><Button variant="contained" color={buttonPrimary ? "primary" : undefined} size="large">{buttonText}</Button></Link>}
                    </Grid>
                    <Grid item md={1} />
                    <Grid item md={6} className={classes.imageWrapper}>
                        <GatsbyImage image={image} alt="img" className={classes.image} />
                    </Grid>
                </React.Fragment>
                    : <React.Fragment>
                        <Grid item md={6} className={classes.imageWrapper}>
                            <GatsbyImage image={image} alt="img" className={classes.image} />
                        </Grid>
                        <Grid item md={1} />
                        <Grid item md={5}>
                            {badge && <Badge text={badge} />}
                            <Typography variant="h3" gutterBottom>
                                {title}
                            </Typography>
                            <Typography variant="body1" gutterBottom className={classes.description}>
                                <ReactMarkdown linkTarget="_blank" className={classes.markdown}>{description}</ReactMarkdown>
                            </Typography>
                            {buttonText && <Link to={url} className={classes.link}><Button variant="contained" color={buttonPrimary ? "primary" : undefined} size="large">{buttonText}</Button></Link>}
                        </Grid>
                    </React.Fragment>}
            </Grid>
            <Grid container spacing={3} className={[classes.root, classes.mobile].join(" ")}>
                <Grid item md={6} className={classes.imageWrapper}>
                    <GatsbyImage image={image} alt="img" className={classes.image} />
                </Grid>
                <Grid item md={1} />
                <Grid item md={5}>
                    {badge && <Badge text={badge} />}
                    <Typography variant="h3" gutterBottom>
                        {title}
                    </Typography>
                    <Typography variant="body1" gutterBottom className={classes.description}>
                        <ReactMarkdown linkTarget="_blank" className={classes.markdown}>{description}</ReactMarkdown>
                    </Typography>
                    {buttonText && <Link to={url} className={classes.link}><Button variant="contained" color={buttonPrimary ? "primary" : undefined} size="large">{buttonText}</Button></Link>}
                </Grid>
            </Grid>
        </div>

    )
}

export default Row
